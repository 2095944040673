import {useAuthStore} from "~/stores/auth";

export default defineNuxtRouteMiddleware(async (to) => {
    const app = useNuxtApp()
    const store = await useAuthStore(app.$pinia)

    if (store.user === undefined) {
        await store.initAuth();

        const user = store.user;

        if (user) {
            return navigateTo('/listings')
        }
    }

    if (store.user) {
        return navigateTo('/listings')
    }
})